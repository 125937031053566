<template>
  <div id="list" style="margin:10px">
    <div v-html="markdownToHtml" />
    <TablePage :showFilterInput="true" :tableData="list.data" :columns="list.header" :loading="list.loadState" placeholder="搜索" />
    <Modal v-model="showModal" title :width="450" :height="450" @on-cancel="onCancel" @on-ok="onConfirm">
      <div @click="hideColor">
      <img :src="currentImageUrl" style="width:100%;height:100%" />
      <div ref="tagContainer">
      <Tag 
        v-for="item in tag.items" 
        :key="item.txt" 
        :name="item.txt" 
        identy="tag"
        :class="item.classname"
        closable
        @on-close="handleClose"
        @click="selectTag">{{ item.txt }}</Tag>
      
      <Input
        class="input-new-tag"
        v-if="tag.inputVisible"
        v-model="tag.inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter="handleInputConfirm"
        @on-blur="handleInputConfirm" />
      
      <Button v-else
        size="small" 
        type="dashed" 
        icon="ios-add" 
        @click="showInput">添加标签</Button>
      </div>
      <div style="display:flex;flex-direction:row" v-if="tag.colorVisible">
        <ul class="tagcolor-select">
          <li class="red" @click="selectColor" tagstyle="mwtag-red"></li>
          <li class="orange" @click="selectColor" tagstyle="mwtag-orange"></li>
          <li class="yellow" @click="selectColor" tagstyle="mwtag-yellow"></li>
          <li class="green" @click="selectColor" tagstyle="mwtag-green"></li>
          <li class="blue" @click="selectColor" tagstyle="mwtag-blue"></li>
          <li class="purple" @click="selectColor" tagstyle="mwtag-purple"></li>
          <li class="gray" @click="selectColor" tagstyle="mwtag-gray"></li>
        </ul>
      </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {ref,reactive} from 'vue';
import TablePage from './TablePage.vue';
import productListData from '../jdproductlist.js';
import { marked } from 'marked';
import Utils from '../utils.js';
import '../assets/customtag.css';

export default {
  name: 'ProductList',
  components: {
    TablePage
  },
  setup() {
    const showModal = ref(false);
    const currentImageUrl = ref('');
    const tag = reactive({
        inputVisible: false,
        inputValue: '',
        colorVisible: false,
        items: [],
        selectedTag: undefined,
        selectedTagIndex: undefined
      });
    return {
      showModal,
      currentImageUrl,
      tag
    }
  },
  data() {
    return {
      markdown: '',
      list: productListData
    }
  },
  computed: {
    markdownToHtml () {
      return marked(this.markdown);
    }
  },
  mounted () {
    window.listData = this.getListData;
    window.displayModal = this.displayModal;
    window.setProductTags = this.setProductTags;

    window.listData();
  },
  methods: {
    // Ajax获取列表数据
    getListData () {
      console.log("getListData...");
      var serverurl = "http://test.miiiw.com/jdtrackerapi/listitems";
      var postdata = {"index":0, "pagesize":-1};

      var self = this;
      fetch(serverurl, {
        method: 'POST', // 请求方法还可以是 put
        body: JSON.stringify(postdata), // 数据可以以字符串或则对象的形式
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      }).then(res => res.json())
        .then(response => {
          // 服务器返回数据时调用回调函数，response 是服务器返回的数据
          // console.log(response.data);
          var dataJson = JSON.parse(response.data.replace(/"/g, '').replace(/None/g, '"N/A"').replace(/'/g, '"'));

          var list = [];

          for(var res in dataJson)
          {
            var item = dataJson[res];
            // console.log(item);
            item.color = item.color.length == 0 ? 'N/A' : item.color;
            item.remark = item.remark.length == 0 ? 'N/A' : item.remark;

            if(!Utils.getOs().isPc)
            {
              item.iconlink = item.iconlink.replace('.avif','');
              item.imglink = item.imglink.replace('.avif','');
            }
            var listItem = {
              skuid: item.jdskuid,
              brand: item.brand,
              spu: item.jdspu,
              color: item.color,
              version: item.version,
              iconlink: item.iconlink,
              imglink: item.imglink,
              remark: item.remark,
              date: item.update_time
            };
            list.push(listItem);
          }
          self.list.data = list;
          self.list.loadState = false;
      });
    },
    displayModal(url) {
      this.showModal = true;
      this.currentImageUrl = url;
    },
    setProductTags(tags){
      console.log("setProductTags");
      this.tag.items = [];
      // var tags = this.list.productTags;      
      for(var item in tags)
      {
        var tag = Object.create(null);
        tag.classname = tags[item].classname + " editableTag";
        tag.txt = tags[item].txt;
        this.tag.items.push(tag);
      }
      console.log(this.tag.items);
    },
    //标签部分事件
    handleClose(tag) {
      // 获取当前标签序号
      var index = 0;
      var child = tag.target.parentNode;
      while((child = child.previousSibling) != null) index ++;
      this.tag.items.splice(index-1, 1);
    },    
    handleInputConfirm(e) {
      console.log('handleInputConfirm');
      let inputValue = this.tag.inputValue;
      if (inputValue) {
        var newtag = {classname:"mwtag-default", txt:inputValue};
        this.tag.items.push(newtag);   //简简单单，push一下
      }
      this.tag.inputVisible = false;
      this.tag.inputValue = '';
    },
    showInput(e) {
      console.log('showInput');
      this.tag.inputVisible = true;
      e.stopPropagation();
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 模式框事件
    onCancel() {
      console.log("onCancle...");
      this.tag.selectedTag = undefined;
      this.tag.selectedTagIndex = undefined;
      this.tag.items = [];//this.list.productTags;
    },
    onConfirm() {
      var remark = [];
      var tags = this.tag.items;
      var nochange = Utils.compareTags(tags, this.list.productTags);
      console.log("tags nochange:"+nochange);
      if(nochange) return;
      for(var item in tags)
      {
        var remarkItem = Object.create(null);
        remarkItem.classname = tags[item].classname.replace(' editableTag', '');
        remarkItem.txt = tags[item].txt;
        remark.push(remarkItem);
      }
      console.log("tag count:"+remark.length);

      var remarkStr = "";      
      if(remark.length > 0)
      {
        remarkStr = JSON.stringify(remark).replace(/"/g, "'");
      }
      console.log("remarkStr:"+remarkStr);

      if(remarkStr.length > 500)
      {
        alert("标签长度超过数据库接受范围!");
      }else{
        var serverurl = "http://test.miiiw.com/jdtrackerapi/updateitemremark";
        var postdata = {"jdskuid":this.list.currentSku, "remark":remarkStr};
        console.log(this.list.currentSku);
        console.log(JSON.stringify(postdata));

        fetch(serverurl, {
          method: 'POST', // 请求方法还可以是 put
          body: JSON.stringify(postdata), // 数据可以以字符串或则对象的形式
          headers: new Headers({
            'Content-Type': 'application/json'
          })
        }).then(res => res.json())
          .then(response => {
            console.log(response);
            this.getListData();
          });
      }
    },
    selectTag(e) {
      var tag = e.target.parentNode;
      if(tag.getAttribute("identy") == null)
      {
        tag = e.target;
      }
      // 获取当前标签序号
      var index = 0;
      var child = tag;
      while((child = child.previousSibling) != null) index ++;
      console.log("select tag:"+index);
      this.tag.selectedTagIndex = index-1;
      
      if(this.tag.selectedTag == undefined)
      {
        this.tag.colorVisible = true;
      }else{
        if(this.tag.selectedTag == tag)
        {
          this.tag.colorVisible = !this.tag.colorVisible;
        }else{
          this.tag.selectedTag.classList.remove('selectedTag');
          this.tag.colorVisible = true;
        }
      }     
      
      if(this.tag.colorVisible)
      {
        tag.classList.add('selectedTag');
      }else{
        tag.classList.remove('selectedTag');
      }
      this.tag.selectedTag = tag;
    },
    selectColor(e) {
      e.stopPropagation();
      console.log("select color:" + e.target.className);
      console.log(this.tag.selectedTag);
      var selectedTag = this.tag.selectedTag;
      var selectedTagTxt = selectedTag.children[0];
      var styles = selectedTag.classList.value.split(' ');

      for(var item in styles)
      {
        if(styles[item].startsWith("mwtag-"))
        {
          selectedTag.classList.remove(styles[item]);
        }
      }
      selectedTag.classList.remove("selectedTag");
      var tagclass = e.target.getAttribute("tagstyle");
      selectedTag.classList.add(tagclass);
      selectedTagTxt.style.color = '#FFFFFF';
      this.tag.colorVisible = false;
      console.log(tagclass);
      this.tag.items[this.tag.selectedTagIndex].classname = tagclass;
      // this.list.productTags[this.tag.selectedTagIndex].classname = tagclass;
    },
    hideColor() {
      this.tag.colorVisible = false;
      if(this.tag.selectedTag != undefined)
      {
        this.tag.selectedTag.classList.remove('selectedTag');
      }
    }
  }
}
</script>
