<template>
  <div style="border:solid 1px #E0E0E0;border-radius:5px;padding:15px">
    <canvas :id="id" :subtitle="subtitleText"/>
  </div>
</template>

<script>
import {reactive,toRefs} from "vue";
import Chart from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
import {DateTime} from 'luxon';
import 'chartjs-adapter-luxon';
import customTooltips from '../customTooltips.js';
import '../assets/customchartjs.css';
import Utils from '../utils.js';

Chart.register(zoomPlugin);

export default {
  props: ['id', 'subtitleText'],
  setup (props) {
    const data = reactive({
      chartData: {
        labels: [],
        datasets: [
          {
            label: "评论数",
            lineTension: 0,
            data: [],
            backgroundColor: "#36a2eb",
            borderColor: "#36a2eb",
            borderWidth: 3
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onResize: (chart, newVal) => {
          var newText = Utils.getStringByWidth(props.subtitleText, '18px sans-serif', newVal.width);
          chart.options.plugins.subtitle.text = newText;
        },
        elements: {
          point:{
              radius: 3,
              fill: true,
              hoverRadius: 4,
              hoverBorderWidth: 5,
          }
        },
        transitions: {
          active: {
            animation: {
              duration: 0
            }
          }
        },
        animation: {
          duration: 0,      
          onComplete: function () {
            var chartInstance = this;
            var ctx = chartInstance.ctx;
            // 以下属于canvas的属性（font、fillStyle、textAlign...）
            var fontHeight = Chart.defaults.font.size;
            ctx.font = Chart.defaults.font;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            var paddingX = 7;
            var paddingY = 5;

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.getDatasetMeta(i);
              if(meta.hidden == null)
              {         
                ctx.fillStyle = dataset.borderColor;
                var pos = i ? -2 : 1;
                meta.data.forEach(function (point, index) {
                  var pointStr = dataset.data[index].y;
                  var txtDimension = ctx.measureText(pointStr);
                  var txtWidth = txtDimension.width;

                  ctx.fillStyle = dataset.borderColor + "30";
                  ctx.fillRect(point.x-txtWidth/2-paddingX, point.y-12*pos-fontHeight-paddingY, txtWidth+paddingX*2, fontHeight+paddingY*2);
                  ctx.fillStyle = dataset.borderColor;
                  ctx.fillText(pointStr, point.x, point.y - 12*pos);
                });
              }
            });
          }
        },
        plugins: {
          title: {
            display: true,
            text: '评论走势图',
            font: {
              size: 20
            },
            padding: {
              top: 0,
              bottom: 0
            }
          },
          subtitle: {
            display: true,
            text: '',
            font: {
              family: 'sans-serif',
              size: 18
            },
            padding: {
              top: 10,
              bottom: 10
            }
          },
          legend: {
            position: 'right',
            padding: 20,      
            labels: {
              boxWidth: 8,
              usePointStyle: true
            }
          },
          tooltip: {
            enabled: false,
            external: customTooltips
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            }
          }
        },
        scales: {
          x: {
            min: DateTime.now().plus({day:-10}).toISO(),
            max: DateTime.now().toISO(),
            type: 'time',
            // distribution: 'linear',//'series',
            time: {
              unit: "day",
              stepSize: 1,
              displayFormats: {
                day: 'yyyy-M-d'
              }
            }
          },
          y: {
            ticks: {
              beginAtZero: false,
              stepSize: 200,
              padding: 15
            }
          }
        },
      }
    });

    return {
      ...toRefs(data)
    }
  },
  mounted () {
    let ctx = document.getElementById(this.id).getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line',
      data: this.chartData,
      options: this.chartOptions
    });
  },
  methods: {
    update() {
      this.chart.update();
    },
  }
}
</script>
