import { Button } from 'view-ui-plus';

function deleteItem(skuid) {
  if(window.confirm("确认删除该条商品信息?"))
  {
    console.log('Delete item:' + skuid);

    var serverurl = "http://test.miiiw.com/jdtrackerapi/deleteitem";
    var postdata = {"jdskuid":skuid};

    console.log(JSON.stringify(postdata));

    fetch(serverurl, {
      method: 'POST', // 请求方法还可以是 put
      body: JSON.stringify(postdata), // 数据可以以字符串或则对象的形式
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }).then(res => res.json())
      .then(response => {
        console.log(response);
        window.listData();
      });    
  }  
}

const productListData = {
  loadState: true,
  productTags: [],
  currentSku: '',
  inputVisible: false,
  inputValue: '',
  header: [
    {
      title: '序号',
      type: 'index',
      align: 'center',
      width: '70'
    },
    {
      title: '商品图片',
      key: 'imglink',
      align: 'center',
      width: '100',
      render: (h, params) => {
        var self = this;
        return h('img', {
          src: params.row.iconlink,
          style: {
            'width': '40px',
            'height': '40px',
            'margin': '10px'
          },
          onClick: e => {            
            // e.stopPropagation(); // 阻止冒泡事件
            productListData.currentSku = params.row.skuid;
            window.displayModal(params.row.imglink);

            var tags = [];
            var remark = params.row.remark;
            if(remark != "N/A")
            {
              remark.map(function(item, index){
                tags.push(item);
              });
            }
            productListData.productTags = tags;
            window.setProductTags(tags);
          }
        })
      }
    },
    {
      title: '品牌',
      key: 'brand',
      width: '150',
      render: (h, params) => {
        return h('span', {
          style: {
            display: 'inline-block',
            width: params.column._width*0.9+'px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        }, params.row.brand)
      }
    },
    {
      title: '商品信息',
      key: 'spu',
      minWidth: 360,
      render: (h, params) => {
        return h('div', [   
          h('div', {
            style: {
              display: 'flex',
            }
          },[
            h('a', {
              href: '/linechart?skuid=' + params.row.skuid,
              title: params.row.spu,
              style: {
                display: 'inline-block',
                width: params.column._width*0.9+'px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }
            }, params.row.spu),
            h('a', {
              href: 'https://item.jd.com/' + params.row.skuid + '.html',
              target: '_blank', 
              style: {
                display: 'flex',
                alignItems: 'center'
              }
            }, [
              h('img',{
                src: '/img/link-two.svg',
                title: '京东商品页',
                style: {
                  width: '16px',
                  marginRight: '5px'
                },
              }),
              h('span',{
                style: {overflow:'hidden', width: '60px'}
              },'京东商品页')
            ])
            
          ]),
          h('ul', {
            style: {
              listStyle: 'none'
            }
          }, [
            h('li', {style:{float:'left'}},'颜色：'+params.row.color),
            h('li', {style:{float:'left',marginLeft:'10px'}},'|'),
            h('li', {style:{float:'left',marginLeft:'10px'}},'版本：'+params.row.version)
          ])              
        ])
      }
    },
    {
      title: '备注',
      key: 'remark',
      width: '150',
      render: (h, params) => {
        var remark = params.row.remark;
        if(remark != "N/A")
        {
          // var arr = JSON.parse(remark);
          // console.log(remark)
          return h('div', remark.map(function(item, index){
            return h('Tag', {
              class: 'ivu-tag ivu-tag-checked ' + item.classname,
            }, [
              h('span', {}, item.txt)
            ])
          }))
        }else{
          return h('span','N/A')
        }
      }
    },
    {
      title: '更新日期',
      key: 'date',
      width: '150'
    },
    {
      title: '操作',
      width: '90',
      align: 'center',
      render: (h, params) => {
        return h('button', {
          class: 'ivu-btn ivu-btn-error ivu-btn-small',
          onClick: e => {
              e.stopPropagation(); // 阻止冒泡事件
              deleteItem(params.row.skuid);   
            }
        }, '删除')
      }
    }
  ],
  data: []
}

export default productListData;