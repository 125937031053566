const Utils = {
  getOs: () => {
    var ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet = /(?:iPad|PlayBook|Macintosh)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian && !isTablet;
    return {
      isTablet: isTablet,
      isPhone: isPhone,
      isAndroid: isAndroid,
      isPc: isPc,
      detail: ua
    };
  },
  getBrowser: () => {
    var ua = navigator.userAgent;
    // 是否为 Opera
    var isOpera = ua.indexOf('Opera') > -1
    if (isOpera) { return 'Opera' }

    // 是否为 IE
    var isIE = (ua.indexOf('compatible') > -1) && (ua.indexOf('MSIE') > -1) && !isOpera
    var isIE11 = (ua.indexOf('Trident') > -1) && (ua.indexOf("rv:11.0") > -1)

    if (isIE11) { return 'IE11'
    } else if (isIE) {
      // 检测是否匹配
      var re = new RegExp('MSIE (\\d+\\.\\d+);')
      re.test(ua)
      // 获取版本
      var ver = parseFloat(RegExp["$1"])

      if (ver == 7) { return 'IE7'
      } else if (ver == 8) { return 'IE8'
      } else if (ver == 9) { return 'IE9'
      } else if (ver == 10) { return 'IE10'
      } else { return "IE" }
    }

    // 是否为 Edge
    var isEdge = ua.indexOf("Edge") > -1
    // 返回结果
    if (isEdge) { return 'Edge' }

    // 是否为 Firefox
    var isFirefox = ua.indexOf("Firefox") > -1
    if (isFirefox) { return 'Firefox' }

    // 是否为 Safari
    var isSafari = (ua.indexOf("Safari") > -1) && (ua.indexOf("Chrome") == -1)
    if (isSafari) { return "Safari" }

    // 是否为 Chrome
    var isChrome = (ua.indexOf("Chrome") > -1) && (ua.indexOf("Safari") > -1) && (ua.indexOf("Edge") == -1)
    if (isChrome) { return 'Chrome' }

    // 是否为 UC
    var isUC= ua.indexOf("UBrowser") > -1
    if (isUC) { return 'UC' }

    // 是否为 QQ
    var isQQ= ua.indexOf("QQBrowser") > -1
    if (isUC) { return 'QQ' }

    // 都不是
    return 'Unkown'
  },
  compareTags: (tags1, tags2) => {
    var equal = true;

    if(tags1.length == tags2.length)
    {
      for(var item in tags1)
      {
        tags1[item].classname = tags1[item].classname.replace(' editableTag', '');
        tags2[item].classname = tags2[item].classname.replace(' editableTag', '');
        if(tags1[item].classname != tags2[item].classname || tags1[item].txt != tags2[item].txt)
        {
          console.log("classname:"+(tags1[item].classname != tags2[item].classname));
          console.log("text:"+(tags1[item].txt != tags2[item].txt)+", "+tags1[item].txt+"|"+tags2[item].txt);
          equal = false;
          break;
        }
      }
    }else{
      equal = false;
    }
    return equal;
  },
  getStringByWidth: (txt, font, width) => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    ctx.font = font;
    var tmpTitle = txt;
    var i = 0;
    while(ctx.measureText(tmpTitle).width > width)
    {
      i ++;
      tmpTitle = tmpTitle.slice(0, tmpTitle.length-i)
    }
    if(i > 0)
    {
      return tmpTitle + "..."
    }
    return tmpTitle;
  }
};

export default Utils;
