<template>
  <div id="line-chart" style="height:100dvh;padding:10px;display:flex;flex-direction:column;align-items:stretch;align-content:stretch">
    <PriceHistoryChart id="pricehistorychart" :subtitleText="originSubtitle" ref="chart1" style="flex-grow:1;min-width:400px;min-height:300px" />
    <CommentHistoryChart id="commenthistorychart" :subtitleText="originSubtitle" ref="chart2" style="margin-top:10px;flex-grow:1;min-width:400px;min-height:300px;" />
  </div>
</template>

<script>
import PriceHistoryChart from "./PriceHistoryChart.vue";
import CommentHistoryChart from '../components/CommentHistoryChart.vue';
import Utils from '../utils.js';
import {DateTime} from 'luxon';
import '../assets/customchartjs.css';

export default {
  name: 'ChartView',
  components: {
    PriceHistoryChart,
    CommentHistoryChart
  },
  data() {
    return {
      originSubtitle: ''
    }
  },
  mounted () {
    if(Utils.getOs().isPc)
    {  
      document.getElementById('line-chart').style.height = '100vh';
    }
    this.query(this.$route.query.skuid);
  },
  methods: {
    query (skuid) {
      var serverurl = "http://test.miiiw.com/jdtrackerapi/querytrackhistory";
      var postdata = {"jdskuid":skuid};
      fetch(serverurl, {
        method: 'POST', // 请求方法还可以是 put
        body: JSON.stringify(postdata), // 数据可以以字符串或则对象的形式
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      }).then(res => res.json())
        .then(response => {
        // 服务器返回数据时调用回调函数，response 是服务器返回的数据
        if(response.code == 0)
        {
          var dataJson = JSON.parse(response.data.replace(/"/g, '').replace(/'/g, '"'));
          if(dataJson.length == 0)
            return;

          var title = "";
          var labels = [];
          var labelprice = [];
          var realprice = [];
          var maxprice = 0;
          var minprice = Number.MAX_VALUE;
          var comment = [];
          var maxcomment = 0;
          var mincomment = Number.MAX_VALUE;
          for(var res in dataJson)
          {
            var item = dataJson[res];
            title = item.item_brand+" "+item.item_name;

            var date = new Date(item.update_time.replace(/-/g, '/'));
            var mils = DateTime.fromMillis(date.getTime()).toISO();
            labels.push(item.update_time);
            labelprice.push({x: mils, y: item.item_labelprice});
            realprice.push({x: mils, y: item.item_realprice});
            maxprice = item.item_labelprice > maxprice ? item.item_labelprice : maxprice;
            minprice = item.item_realprice < minprice ? item.item_realprice : minprice;

            comment.push({x: mils, y: item.item_comment_count});
            maxcomment = Number(item.item_comment_count) > maxcomment ? item.item_comment_count : maxcomment;
            mincomment = Number(item.item_comment_count) < mincomment ? item.item_comment_count : mincomment;
          }
          this.originSubtitle = title; // 保存一份原始的subtitle值用于缩放

          var priceStepSize = 20;
          maxprice = Math.ceil(maxprice/priceStepSize)*priceStepSize;
          minprice = Math.floor(minprice/priceStepSize)*priceStepSize;

          var commentStepSize = 200;
          maxcomment = Math.ceil(maxcomment/commentStepSize)*commentStepSize;
          mincomment = Math.floor(mincomment/commentStepSize)*commentStepSize;
          commentStepSize = maxcomment > 1000 ? 1000 : commentStepSize;

          let chart1 = this.$refs.chart1;
          let chart2 = this.$refs.chart2;

          title = Utils.getStringByWidth(title, '18px sans-serif', document.getElementById('pricehistorychart').width);
          chart1.chartOptions.plugins.subtitle.text = title;
          chart2.chartOptions.plugins.subtitle.text = title;

          var minpriceDisplay = (minprice - priceStepSize) > 0 ? (minprice - priceStepSize) : 0
          chart1.chartOptions.scales.y.max = maxprice + priceStepSize;
          chart1.chartOptions.scales.y.min = minpriceDisplay;
          chart1.chartOptions.scales.y.ticks.stepSize = priceStepSize;

          chart1.chartData.labels = labels;
          chart1.chartData.datasets[0].data = labelprice;
          chart1.chartData.datasets[1].data = realprice;

          var mincommentDisplay = (mincomment - commentStepSize) > 0 ? (mincomment - commentStepSize) : 0;
          chart2.chartOptions.scales.y.max = maxcomment + commentStepSize;          
          chart2.chartOptions.scales.y.min = mincommentDisplay;
          chart2.chartOptions.scales.y.ticks.stepSize = commentStepSize;

          chart2.chartData.datasets[0].data = comment;

          chart1.update();
          chart2.update();
        }
      });
    }
  }
}
</script>
