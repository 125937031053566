import {createRouter, createWebHistory} from 'vue-router'
import ProductList from '@/components/ProductList'
import LineChart from '@/components/LineChart'

const routes = [
  {
    path:'/',
    component:ProductList,
    meta: {
      title: '产品列表'
    }
  },
  {
    path:'/linechart',
    component:LineChart,
    meta: {
      title: '商品趋势图'
    }
  },
]

const routerHistory = createWebHistory()
const routers = createRouter({
    history: routerHistory,
    routes: routes
})

routers.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  if(to.meta.title){//判断是否有标题
      document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})

export default routers