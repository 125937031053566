function customTooltips(context) {
  // Tooltip Element
  var tooltipEl = document.getElementById("chartjs-tooltip");  

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = "<table></table>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  var tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("top", "bottom", "center", "left", "right");
  if (tooltipModel.yAlign || tooltipModel.xAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
    tooltipEl.classList.add(tooltipModel.xAlign);
  }

  // Set Text
  if (tooltipModel.body) {
    var titleLines = tooltipModel.title || [];
    var bodyLines = tooltipModel.body.map((bodyItem) => {
      return bodyItem.lines;
    });

    var innerHtml = "<thead>";

    titleLines.forEach(function (title) {
      innerHtml += '<tr><th><div class="mb-1">' + title + "</div></th></tr>";
    });
    innerHtml += "</thead><tbody>";

    bodyLines.forEach((body, i) => {
      var colors = tooltipModel.labelColors[i];
      var style = 'background-color:' + colors.borderColor;
      var value = tooltipModel.dataPoints[i].raw.y.toFixed(2);
      var label = this._chart.data.datasets[i].label;

      style += "; border-color:" + colors.borderColor;
      style += "; border-color:" + this._chart.data.datasets[i].borderColor;
      style += "; border-width: 2px";

      var span =
        '<span class="chartjs-tooltip-key" style="' + style + '"></span>';

      innerHtml += `<tr><td> ${span} ${label}: ￥${value} </td></tr>`;
    });
    innerHtml += "</tbody>";

    var tableRoot = tooltipEl.querySelector("table");
    tableRoot.innerHTML = innerHtml;
  }
  const rect = tooltipEl.getBoundingClientRect();

  let space = context.chart.options.elements.point.hoverRadius*2 + 3;
  let left = context.chart.canvas.offsetLeft + window.pageXOffset + tooltipModel.caretX - rect.width/2;
  let top = context.chart.canvas.offsetTop + window.pageYOffset + tooltipModel.caretY - rect.height;

  // yAlign could be: `top`, `bottom`, `center`
  if (tooltipModel.yAlign === "top") {
    top += rect.height + space;
  } else if (tooltipModel.yAlign === "center") {
    top += rect.height / 2;
  } else if (tooltipModel.yAlign === "bottom") {
    top -= space;
  }
  // xAlign could be: `left`, `center`, `right`
  if (tooltipModel.xAlign === "left") {
    left += rect.width / 2 - space / 2;
    if (tooltipModel.yAlign === "center") {
      left += space * 2;
    }
  } else if (tooltipModel.xAlign === "right") {
    left -= rect.width / 2 + space / 2;
    if (tooltipModel.yAlign === "center") {
      left -= space;
    } else {
      left += space;
    }
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;

  // Left and right
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = top + 'px';
  
  // Font
  tooltipEl.style.font = context.chart.ctx.font;

  // Paddings
  tooltipEl.style.padding = '3px 3px';
}

export default customTooltips;