<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
@import 'view-ui-plus/dist/styles/viewuiplus.css';
@import './assets/customtag.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  height: calc(100vh - 20px);
}
* {
  font-size: 9pt;
}
</style>
